import React, { Suspense, lazy } from 'react';
import {BrowserRouter as Router,Redirect,Switch,Route} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../App.css';
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
const Contactus = lazy(() => import('./Contactus'));
const MyPage = lazy(() => import('./MyPage'));
const AllVideos = lazy(() => import('./AllVideos'));
const VideoPreview = lazy(() => import('./VideoPreview'));
const Platinum = lazy(() => import('./Platinum'));
const Gold = lazy(() => import('./Gold'));
const Silver = lazy(() => import('./Silver'));
const Books = lazy(() => import('./Books'));
const BooksPreview = lazy(() => import('./BooksPreview'));
const Art = lazy(() => import('./Art'));
const ArtPreview = lazy(() => import('./ArtPreview'));
const Music = lazy(() => import('./Music'));
const Portfolio = lazy(() => import('./Portfolio'));
const PortfolioPreview = lazy(() => import('./PortfolioPreview'));
const Advertisement = lazy(() => import('./Advertisement'));
const ShortVideos = lazy(() => import('./ShortVideos'));
const BookTrailers = lazy(() => import('./BookTrailers'));
const ArtTrailers = lazy(() => import('./ArtTrailers'));
const Productsvideos = lazy(() => import('./Productsvideos'));
const Servicesvideos = lazy(() => import('./Servicesvideos'));
const AuthorInterviews = lazy(() => import('./AuthorInterviews'));
const Reviews = lazy(() => import('./Reviews'));
const Photography = lazy(() => import('./Photography'));
const MovieTrailers = lazy(() => import('./MovieTrailers'));
const Products = lazy(() => import('./Products'));
const ProductsPreview = lazy(() => import('./ProductsPreview'));
const Services = lazy(() => import('./Services'));
const ServicesPreview = lazy(() => import('./ServicesPreview'));
const Photos = lazy(() => import('./Photos'));
const PhotosPreview = lazy(() => import('./PhotosPreview'));
const Search = lazy(() => import('./search/Search'));
const ForgetPassword = lazy(() => import('./login/ForgetPassword'));
const ChangePassword = lazy(() => import('./login/ChangePassword'));
const Login = lazy(() => import('./login/Login'));
const Register = lazy(() => import('./login/Register'));
const Logout = lazy(() => import('./login/Logout'));
const Myaccount = lazy(() => import('./account/Myaccount'));
const AdminSection = lazy(() => import('./account/AdminSection'));
const AddVideo = lazy(() => import('./account/AddVideo'));
const EditVideo = lazy(() => import('./account/EditVideo'));
const AddBook = lazy(() => import('./account/AddBook'));
const AddProduct = lazy(() => import('./account/AddProduct'));
const EditProduct = lazy(() => import('./account/EditProduct'));
const AddServices = lazy(() => import('./account/AddServices'));
const EditServices = lazy(() => import('./account/EditServices'));
const AddPhoto = lazy(() => import('./account/AddPhoto'));
const EditPhoto = lazy(() => import('./account/EditPhoto'));
const EditBooks = lazy(() => import('./account/EditBooks'));
const AddPortfolio = lazy(() => import('./account/AddPortfolio'));
const AddPainting = lazy(() => import('./account/AddPainting'));
const EditPainting = lazy(() => import('./account/EditPainting'));
const EditProfile = lazy(() => import('./account/EditProfile'));
const EditPortfolios = lazy(() => import('./account/EditPortfolios'));
const PaymentSection = lazy(() => import('./payment/PaymentSection'));
const Recepits = lazy(() => import('./payment/Recepits'));
const StripeSuccess = lazy(() => import('./payment/StripeSuccess'));
const StripeSuccessUpgrade = lazy(() => import('./payment/StripeSuccessUpgrade'));
const StripeCancel = lazy(() => import('./payment/StripeCancel'));
const StripeCancelUpgrade = lazy(() => import('./payment/StripeCancelUpgrade'));
const PaypalSuccess = lazy(() => import('./payment/PaypalSuccess'));
const PaypalSuccessUpgrade = lazy(() => import('./payment/PaypalSuccessUpgrade'));
const PaypalCancel = lazy(() => import('./payment/PaypalCancel'));
const PaypalCancelUpgrade = lazy(() => import('./payment/PaypalCancelUpgrade'));
const PaypalBack = lazy(() => import('./payment/PaypalBack'));
const PaypalBackUpgrade = lazy(() => import('./payment/PaypalBackUpgrade'));
const UpgradePlanList = lazy(() => import('./payment/UpgradePlanList'));
const NotFound = lazy(() => import("./NotFound"));
const Demo = lazy(() => import("./Demo"));

function Dashboard() {
  return (<>   
    <Router basename="/">
      <Suspense fallback={<div className="loader"><span>Loading...</span></div>}>
        <ToastContainer />
        <Switch>
         <Route exact path="/" component={Home}/>
         <Route exact path="/About" component={About}/>
         <Route exact path="/AllVideos" component={AllVideos}/>
         <Route exact path="/Platinum" component={Platinum}/>
         <Route exact path="/Gold" component={Gold}/>
         <Route exact path="/Silver" component={Silver}/>
         <Route exact path="/Music" component={Music}/>
         <Route exact path="/Portfolio" component={Portfolio}/>
         <Route exact path="/Search" component={Search}/>
         <Route exact path="/Advertisement" component={Advertisement}/>
         <Route exact path="/ShortVideos" component={ShortVideos}/>
         <Route exact path="/BookTrailers" component={BookTrailers}/>
         <Route exact path="/Productsvideos" component={Productsvideos}/>
         <Route exact path="/Servicesvideos" component={Servicesvideos}/>
         <Route exact path="/AdminSection" component={AdminSection}/>
         <Route exact path="/ArtTrailers" component={ArtTrailers}/>
         <Route exact path="/AuthorInterviews" component={AuthorInterviews}/>
         <Route exact path="/Reviews" component={Reviews}/>
         <Route exact path="/Photography" component={Photography}/>
         <Route exact path="/MovieTrailers" component={MovieTrailers}/>
         <Route exact path="/Books" component={Books}/>
         <Route exact path="/Art" component={Art}/>
         <Route exact path="/Products" component={Products}/>
         <Route exact path="/Services" component={Services}/>
         <Route exact path="/Photos" component={Photos}/>
         <Route exact path="/Login" component={Login}/>
         <Route exact path="/Register" component={Register}/>
         <Route exact path="/ForgetPassword" component={ForgetPassword}/>
         <Route exact path="/ChangePassword/:passkey" component={ChangePassword}/>
         <Route exact path="/Logout" component={Logout}/>
         <Route exact path="/Contactus" component={Contactus}/>
         <Route exact path="/Myaccount" component={Myaccount}/>
         <Route exact path="/PaymentSection" component={PaymentSection}/>
         <Route exact path="/StripeSuccess/:Addpreview" component={StripeSuccess}/>
         <Route exact path="/StripeCancel" component={StripeCancel}/>
         <Route exact path="/StripeCancelUpgrade" component={StripeCancelUpgrade}/>
         <Route exact path="/PaypalBack" component={PaypalBack}/>
         <Route exact path="/PaypalBackUpgrade" component={PaypalBackUpgrade}/>
         <Route exact path="/PaypalSuccess/:Addpreview" component={PaypalSuccess}/>
         <Route exact path="/PaypalSuccessUpgrade/:Addpreview" component={PaypalSuccessUpgrade}/>
         <Route exact path="/PaypalCancel/:Addpreview" component={PaypalCancel}/>
         <Route exact path="/PaypalCancelUpgrade/:Addpreview" component={PaypalCancelUpgrade}/>
         <Route exact path="/AddVideo" component={AddVideo}/>
         <Route exact path="/EditVideo/:Addpreview" component={EditVideo}/>
         <Route exact path="/AddBook" component={AddBook}/>
         <Route exact path="/AddProduct" component={AddProduct}/>
         <Route exact path="/AddServices" component={AddServices}/>
         <Route exact path="/AddPhoto" component={AddPhoto}/>
         <Route exact path="/EditPhoto/:Addpreview" component={EditPhoto}/>
         <Route exact path="/EditServices/:Addpreview" component={EditServices}/>
         <Route exact path="/EditProduct/:Addpreview" component={EditProduct}/>
         <Route exact path="/EditBooks/:Addpreview" component={EditBooks}/>
         <Route exact path="/AddPortfolio/:Addpreview" component={AddPortfolio}/>
         <Route exact path="/AddPainting" component={AddPainting}/>
         <Route exact path="/EditPainting/:Addpreview" component={EditPainting}/>
         <Route exact path="/EditProfile" component={EditProfile}/>
         <Route exact path="/UpgradePlanList" component={UpgradePlanList}/>
         <Route exact path="/StripeSuccessUpgrade/:Addpreview" component={StripeSuccessUpgrade}/>
         <Route exact path="/EditPortfolios/:Addpreview" component={EditPortfolios}/>
         <Route exact path="/MyPage/:Addpreview" component={MyPage}/>
         <Route exact path="/VideoPreview/:Addpreview/:title" component={VideoPreview}/>
         <Route exact path="/BooksPreview/:Addpreview/:title" component={BooksPreview}/>
         <Route exact path="/ArtPreview/:Addpreview/:title" component={ArtPreview}/>
         <Route exact path="/ProductsPreview/:Addpreview/:title" component={ProductsPreview}/>
         <Route exact path="/ServicesPreview/:Addpreview/:title" component={ServicesPreview}/>
         <Route exact path="/PhotosPreview/:Addpreview/:title" component={PhotosPreview}/>
         <Route exact path="/PortfolioPreview/:Addpreview/:title" component={PortfolioPreview}/>
         <Route exact path="/Recepits/:Addpreview" component={Recepits}/>
          

         <Route exact path="/404" component={NotFound} />
         <Route exact path="/Demo" component={Demo} />
         <Redirect to="/404" />

        </Switch>
      </Suspense>
    </Router>
    
    </>
  );
}
export default Dashboard;
