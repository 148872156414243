import React, { createContext,Component } from "react";
import axios from 'axios';
import { WEB_URL } from './Apilinks';
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    baseURL: WEB_URL,
});

class MyContextProvider extends Component{
    state = {
        isAuthTrailer:false,
        theUserIDTrailer:null,
        Trailerplan:null,
        Traileractive:null,
    }
    componentDidMount(){
        this.isLoggedIn();
    }
  
        isLoggedIn = async () => {
            let loginID = JSON.parse(localStorage.getItem('loginID'));
            let loginplan = JSON.parse(localStorage.getItem('loginplan'));
            let loginplanactive = JSON.parse(localStorage.getItem('loginplanactive'));
            if (localStorage.getItem('loginID')) {
                this.setState({
                    ...this.state,
                    isAuthTrailer:true,
                    theUserIDTrailer:loginID,
                    Trailerplan:loginplan,
                    Traileractive:loginplanactive,
                    
                });
            }
        }
        issetpaysss = async () => {
            let loginplanactive = JSON.parse(localStorage.getItem('loginplanactive'));
            if (localStorage.getItem('loginplanactive')) {
                this.setState({
                    ...this.state,
                    Traileractive:loginplanactive,
                });
            }
            
        }
        logoutUser = () => {
            localStorage.removeItem('loginID');
            this.setState({
                ...this.state,
                isAuthTrailer:false,
                theUserIDTrailer:null,
                Trailerplan:null,
                Traileractive:null,
            })
            window.location.href = '/Login';
        }
        RegisterUser = async (userInfo,recaptcha_response) => {
            const registerqqq = await Axios.post('register.php',{
                user_email:userInfo.email,
                user_name:userInfo.username,
                password:userInfo.password,
                subscribe:userInfo.subscribe,
                recaptcha_response:recaptcha_response,
            });
            return registerqqq.data;
        }
        LoginUser = async (user) => {
            const LoginUserqqq = await Axios.post('login.php',{
                email:user.email,
                password:user.password,
            });
            return LoginUserqqq.data;
        }
   
        plansall = async () => {
            const plansall = await Axios.get('price.php');
            return plansall.data;
        }
        planupdateall = async (plan,price,useridsss) => {
            const planupdateallqqq = await Axios.post('planupdate.php',{
                plan:plan,
                price:price,
                userids:useridsss
            });
            return planupdateallqqq.data;
        }
        planupdateallupgrade = async (plan,price,useridsss) => {
            const planupdateallupgradeqqq = await Axios.post('planupdateupgrade.php',{
                plan:plan,
                price:price,
                userids:useridsss
            });
            return planupdateallupgradeqqq.data;
        }
        audioplansall = async (ids) => {
            const audioplansall = await Axios.post('audioplansview.php',{
                bookids:ids,
            });
            return audioplansall.data;
        }
        bookpaysmsg = async (payids) => {
            const bookpaysmsgqqq = await Axios.post('stripesuccess.php',{
                payids:payids,
            });
            return bookpaysmsgqqq.data;
        }
        bookpaypalall = async (payids) => {
            const bookpaypalall = await Axios.post('paypalsuccess.php',{
                payids:payids,
            });
            return bookpaypalall.data;
        }
        userlistlimit = async (ids) => {
            const userlistlimitqqq = await Axios.post('limitprofile.php',{
                userids:ids,
            });
            return userlistlimitqqq.data;
        }
        useradscountall = async (ids) => {
            const useradscountallqqq = await Axios.post('useradscount.php',{
                userids:ids,
            });
            return useradscountallqqq.data;
        }
        addvideourl = async (books,count,userids) => {
            const formData = new FormData();
            formData.append('url',books.url);
            formData.append('title',books.title);
            formData.append('avatar',books.singlefile);
            formData.append('singlefileactive',books.singlefileactive);
            formData.append('abouts',books.abouts);
            formData.append('category',books.category);
            formData.append('descothers',books.descothers);
            formData.append('count',count);
            formData.append('userids',userids);
            const addvideourl = await Axios.post('addvideourl.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return addvideourl.data;
        }
        addvideouploadall = async (books,count,userids) => {
            const formData = new FormData();
            formData.append('title',books.title);
            formData.append('avatar',books.singlefile);
            formData.append('singlefileactive',books.singlefileactive);
            formData.append('videofile',books.videofile);
            formData.append('videofileactive',books.videofileactive);
            formData.append('abouts',books.abouts);
            formData.append('category',books.category);
            formData.append('descothers',books.descothers);
            formData.append('count',count);
            formData.append('userids',userids);
            const addvideouploadallqqq = await Axios.post('addvideoupload.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return addvideouploadallqqq.data;
        }
        videosectionall = async (ids) => {
            const videosectionallqqq = await Axios.post('videosectionall.php',{
                bookids:ids,
            });
            return videosectionallqqq.data;
        }
        videoeditall = async (ids) => {
            const videoeditallqqq = await Axios.post('videoeditall.php',{
                ids:ids,
            });
            return videoeditallqqq.data;
        }
        editvideourl = async (books,videoids) => {
            const formData = new FormData();
            formData.append('url',books.url);
            formData.append('title',books.title);
            formData.append('avatar',books.singlefile);
            formData.append('singlefileactive',books.singlefileactive);
            formData.append('videofile',books.videofile);
            formData.append('videofileactive',books.videofileactive);
            formData.append('abouts',books.abouts);
            formData.append('category',books.category);
            formData.append('descothers',books.descothers);
            formData.append('videoids',videoids);
            const editvideourlqqq = await Axios.post('editvideourl.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return editvideourlqqq.data;
        }
        editvideouploadall = async (books,videoids) => {
            const formData = new FormData();
            formData.append('title',books.title);
            formData.append('avatar',books.singlefile);
            formData.append('singlefileactive',books.singlefileactive);
            formData.append('videofile',books.videofile);
            formData.append('videofileactive',books.videofileactive);
            formData.append('abouts',books.abouts);
            formData.append('category',books.category);
            formData.append('descothers',books.descothers);
            formData.append('videoids',videoids);
            const editvideouploadallqqq = await Axios.post('editvideoupload.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return editvideouploadallqqq.data;
        }
        Booksaddall = async (books,purchase,purchagelength,theUserid) => {
            const formData = new FormData();
            formData.append('title',books.title);
            formData.append('avatar',books.singlefile);
            formData.append('purchase',purchase);
            formData.append('purchagelength',purchagelength.length);
            formData.append('category',books.category);
            formData.append('descothers',books.descothers);
            formData.append('price',books.price);
            formData.append('currency',books.currency);
            formData.append('website',books.website);
            formData.append('abouts',books.abouts);
            formData.append('theUserid',theUserid);
            const Booksaddallqqq = await Axios.post('Booksadd.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return Booksaddallqqq.data;
        }
        editaddall = async (books,purchase,purchagelength,bookids) => {
            const formData = new FormData();
            formData.append('title',books.title);
            formData.append('avatar',books.singlefile);
            formData.append('purchase',purchase);
            formData.append('purchagelength',purchagelength.length);
            formData.append('category',books.category);
            formData.append('descothers',books.descothers);
            formData.append('price',books.price);
            formData.append('currency',books.currency);
            formData.append('website',books.website);
            formData.append('abouts',books.abouts);
            formData.append('bookids',bookids);
            const editaddallqqq = await Axios.post('booksedit.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return editaddallqqq.data;
        }
        bookeditall = async (ids) => {
            const bookeditallqqq = await Axios.post('bookeditall.php',{
                ids:ids,
            });
            return bookeditallqqq.data;
        }
        portfolioeditall = async (ids) => {
            const portfolioeditallqqq = await Axios.post('portfolioeditall.php',{
                ids:ids,
            });
            return portfolioeditallqqq.data;
        }
        paintingaddall = async (books,purchase,purchagelength,theUserid) => {
            const formData = new FormData();
            formData.append('title',books.title);
            formData.append('avatar',books.singlefile);
            formData.append('purchase',purchase);
            formData.append('purchagelength',purchagelength.length);
            formData.append('category',books.category);
            formData.append('descothers',books.descothers);
            formData.append('price',books.price);
            formData.append('currency',books.currency);
            formData.append('website',books.website);
            formData.append('abouts',books.abouts);
            formData.append('phone',books.phone);
            formData.append('email',books.email);
            formData.append('theUserid',theUserid);
            const paintingaddallqqq = await Axios.post('paintingadd.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return paintingaddallqqq.data;
        }
        editpaintingall = async (books,purchase,purchagelength,bookids) => {
            const formData = new FormData();
            formData.append('title',books.title);
            formData.append('avatar',books.singlefile);
            formData.append('purchase',purchase);
            formData.append('purchagelength',purchagelength.length);
            formData.append('category',books.category);
            formData.append('descothers',books.descothers);
            formData.append('price',books.price);
            formData.append('currency',books.currency);
            formData.append('website',books.website);
            formData.append('abouts',books.abouts);
            formData.append('phone',books.phone);
            formData.append('email',books.email);
            formData.append('bookids',bookids);
            const editpaintingallqqq = await Axios.post('paintingedit.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return editpaintingallqqq.data;
        }
        portfolioaddall = async (purchase,purchagelength,userids,types) => {
            const formData = new FormData();
            formData.append('purchase',purchase);
            formData.append('purchagelength',purchagelength.length);
            formData.append('userids',userids);
            formData.append('types',types);
            const portfolioaddallqqq = await Axios.post('portfolioadd.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return portfolioaddallqqq.data;
        }
        paintingeditall = async (ids) => {
            const paintingeditallqqq = await Axios.post('paintingeditlist.php',{
                ids:ids,
            });
            return paintingeditallqqq.data;
        }
        userlistall = async (ids) => {
            const userlistallqqq = await Axios.post('authorprofile.php',{
                userids:ids,
            });
            return userlistallqqq.data;
        }
        editprofilsssall = async (books,purchase,purchagelength,userids) => {
            const formData = new FormData();
            formData.append('name',books.name);
            formData.append('avatar',books.singlefile);
            formData.append('website',books.website);
            formData.append('abouts',books.abouts);
            formData.append('purchase',purchase);
            formData.append('purchagelength',purchagelength.length);
            formData.append('userids',userids);
            const editprofilsssall = await Axios.post('editprofile.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return editprofilsssall.data;
        }
        deletesimglist = async (ids) => {
            const deletesimglistsss = await Axios.post('deletesimg.php',{
                ids:ids,
            });
            return deletesimglistsss.data;
        }
        videopreviewall = async (ids) => {
            const videopreviewallqqq = await Axios.post('videopreviewall.php',{
                ids:ids,
            });
            return videopreviewallqqq.data;
        }
        userpreviewall = async (ids) => {
            const userpreviewall = await Axios.post('userpreviewall.php',{
                ids:ids,
            });
            return userpreviewall.data;
        }
        bookpreviewall = async (ids) => {
            const bookpreviewallqqq = await Axios.post('bookpreviewall.php',{
                ids:ids,
            });
            return bookpreviewallqqq.data;
        }
        artpreviewall = async (ids) => {
            const artpreviewallqqq = await Axios.post('artpreviewall.php',{
                ids:ids,
            });
            return artpreviewallqqq.data;
        }
        Platinumhomesall = async () => {
            const Platinumhomesallqqq = await Axios.get('platinumhomes.php');
            return Platinumhomesallqqq.data;
        }
        Goldhomesall = async () => {
            const Goldhomesallqqq = await Axios.get('goldhomes.php');
            return Goldhomesallqqq.data;
        }
        Silverhomesall = async () => {
            const Silverhomesallqqq = await Axios.get('silverhomes.php');
            return Silverhomesallqqq.data;
        }
        AllVideolist  = async (pageNumber) => {
            const AllVideolistqqq = await Axios.get(`AllVideolist.php?page=${pageNumber}`);
            return AllVideolistqqq.data;
        }
        AllBookslist  = async (pageNumber) => {
            const AllBookslistqqq = await Axios.get(`AllBookslist.php?page=${pageNumber}`);
            return AllBookslistqqq.data;
        }
        AllArtlist  = async (pageNumber) => {
            const AllArtlist = await Axios.get(`AllArtlist.php?page=${pageNumber}`);
            return AllArtlist.data;
        }
        AllMusiclist  = async (pageNumber) => {
            const AllMusiclistqqq = await Axios.get(`AllMusiclist.php?page=${pageNumber}`);
            return AllMusiclistqqq.data;
        }
        AllSilverlist  = async (pageNumber) => {
            const AllSilverlistqqq = await Axios.get(`AllSilverlist.php?page=${pageNumber}`);
            return AllSilverlistqqq.data;
        }
        AllGoldlist  = async (pageNumber) => {
            const AllGoldlistqqq = await Axios.get(`AllGoldlist.php?page=${pageNumber}`);
            return AllGoldlistqqq.data;
        }
        AllPlatinumlist  = async (pageNumber) => {
            const AllPlatinumlistqqq = await Axios.get(`AllPlatinumlist.php?page=${pageNumber}`);
            return AllPlatinumlistqqq.data;
        }
        productaddall = async (books,purchase,purchagelength,theUserid) => {
            const formData = new FormData();
            formData.append('title',books.title);
            formData.append('avatar',books.singlefile);
            formData.append('purchase',purchase);
            formData.append('purchagelength',purchagelength.length);
            formData.append('price',books.price);
            formData.append('currency',books.currency);
            formData.append('website',books.website);
            formData.append('abouts',books.abouts);
            formData.append('theUserid',theUserid);
            const productaddallqqq = await Axios.post('productaddall.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return productaddallqqq.data;
        }
        serviceaddall = async (books,theUserid) => {
            const formData = new FormData();
            formData.append('title',books.title);
            formData.append('avatar',books.singlefile);
            formData.append('link',books.link);
            formData.append('website',books.website);
            formData.append('abouts',books.abouts);
            formData.append('theUserid',theUserid);
            const serviceaddallqqq = await Axios.post('serviceaddall.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return serviceaddallqqq.data;
        }
        producteditall = async (ids) => {
            const producteditallqqq = await Axios.post('producteditall.php',{
                ids:ids,
            });
            return producteditallqqq.data;
        }
        serviceseditall = async (ids) => {
            const serviceseditallqqq = await Axios.post('serviceseditall.php',{
                ids:ids,
            });
            return serviceseditallqqq.data;
        }
        photoeditall = async (ids) => {
            const photoeditallqqq = await Axios.post('photoeditall.php',{
                ids:ids,
            });
            return photoeditallqqq.data;
        }
        editproductall = async (books,purchase,purchagelength,theUserid) => {
            const formData = new FormData();
            formData.append('title',books.title);
            formData.append('avatar',books.singlefile);
            formData.append('purchase',purchase);
            formData.append('purchagelength',purchagelength.length);
            formData.append('price',books.price);
            formData.append('currency',books.currency);
            formData.append('website',books.website);
            formData.append('abouts',books.abouts);
            formData.append('theUserid',theUserid);
            const editproductallqqq = await Axios.post('editproductall.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return editproductallqqq.data;
        }
        editserviceall = async (books,bookids) => {
            const formData = new FormData();
            formData.append('title',books.title);
            formData.append('avatar',books.singlefile);
            formData.append('link',books.link);
            formData.append('website',books.website);
            formData.append('abouts',books.abouts);
            formData.append('bookids',bookids);
            const editserviceallqqq = await Axios.post('editserviceall.php',formData,{
                headers: {'content-type': 'multipart/form-data' }
            });
            return editserviceallqqq.data;
        }
        deletesphotolist= async (ids) => {
            const deletesphotolist = await Axios.post('deletesphoto.php',{
                ids:ids,
            });
            return deletesphotolist.data;
        }
        AllProductslist  = async (pageNumber) => {
            const AllProductslist = await Axios.get(`AllProductslist.php?page=${pageNumber}`);
            return AllProductslist.data;
        }
        AllServiceslist  = async (pageNumber) => {
            const AllServiceslist = await Axios.get(`AllServiceslist.php?page=${pageNumber}`);
            return AllServiceslist.data;
        }
        AllPhotoslist  = async (pageNumber) => {
            const AllPhotoslist = await Axios.get(`AllPhotoslist.php?page=${pageNumber}`);
            return AllPhotoslist.data;
        }
        Productspreviewall = async (ids) => {
            const Productspreviewallqqq = await Axios.post('Productspreviewall.php',{
                ids:ids,
            });
            return Productspreviewallqqq.data;
        }
        Servicespreviewall = async (ids) => {
            const Servicespreviewallqqq = await Axios.post('Servicespreviewall.php',{
                ids:ids,
            });
            return Servicespreviewallqqq.data;
        }
        Photospreviewall = async (ids) => {
            const Photospreviewallqqq = await Axios.post('Photospreviewall.php',{
                ids:ids,
            });
            return Photospreviewallqqq.data;
        }
        AllAuthorInterviewslist  = async (pageNumber) => {
            const AllAuthorInterviewslist = await Axios.get(`AllAuthorInterviewslist.php?page=${pageNumber}`);
            return AllAuthorInterviewslist.data;
        }

        AllReviewslist  = async (pageNumber) => {
            const AllReviewslist = await Axios.get(`AllReviewslist.php?page=${pageNumber}`);
            return AllReviewslist.data;
        }
        AllPhotographylist  = async (pageNumber) => {
            const AllPhotographylist = await Axios.get(`AllPhotographylist.php?page=${pageNumber}`);
            return AllPhotographylist.data;
        }
        AllMovieTrailerslist  = async (pageNumber) => {
            const AllMovieTrailerslist = await Axios.get(`AllMovieTrailerslist.php?page=${pageNumber}`);
            return AllMovieTrailerslist.data;
        }
        AllBookTrailerslist  = async (pageNumber) => {
            const AllBookTrailerslistqqq = await Axios.get(`AllBookTrailerslist.php?page=${pageNumber}`);
            return AllBookTrailerslistqqq.data;
        }
        AllShortVideoslist  = async (pageNumber) => {
            const AllShortVideoslistqqq = await Axios.get(`AllShortVideoslist.php?page=${pageNumber}`);
            return AllShortVideoslistqqq.data;
        }
        AllAdvertisementlist  = async (pageNumber) => {
            const AllAdvertisementlistqqq = await Axios.get(`AllAdvertisementlist.php?page=${pageNumber}`);
            return AllAdvertisementlistqqq.data;
        }
        AllPortfoliolist  = async (pageNumber) => {
            const AllPortfoliolistqqq = await Axios.get(`AllPortfoliolist.php?page=${pageNumber}`);
            return AllPortfoliolistqqq.data;
        }
        AllArtTrailerslist  = async (pageNumber) => {
            const AllArtTrailerslistqqq = await Axios.get(`AllArtTrailerslist.php?page=${pageNumber}`);
            return AllArtTrailerslistqqq.data;
        }
        AllServicesvideos  = async (pageNumber) => {
            const AllServicesvideosqqq = await Axios.get(`AllServicesvideos.php?page=${pageNumber}`);
            return AllServicesvideosqqq.data;
        } 
        AllProductsvideo  = async (pageNumber) => {
            const AllProductsvideoqqq = await Axios.get(`AllProductsvideo.php?page=${pageNumber}`);
            return AllProductsvideoqqq.data;
        } 
        Portfoliopreviewall  = async (ids) => {
            const Portfoliopreviewallqqq = await Axios.post('Portfoliopreviewall.php',{
                ids:ids,
            });
            return Portfoliopreviewallqqq.data;
        }
        Latesthomesall = async () => {
            const Latesthomesallqqq = await Axios.get('latesthomesall.php');
            return Latesthomesallqqq.data;
        }
        Bookshomesall = async () => {
            const Bookshomesallqqq = await Axios.get('bookshomesall.php');
            return Bookshomesallqqq.data;
        }
        addnewsletters = async (user) => {
            const addnewsletterssss = await Axios.post('addnewsletters.php',{
                email:user.email,
            });
            return addnewsletterssss.data;
        }
        audioreceiptall = async (ids) => {
            const audioreceiptallqqq = await Axios.post('receipts.php',{
                booksids:ids,
            });
            return audioreceiptallqqq.data;
        }
        ForgetMailpassword = async (user) => {
            const loginForget = await Axios.post('forgetpassword.php',{
                email:user.email,
            });
            return loginForget.data;
        }
        ChangePasswordall = async (user,passkey) => {
            const ChangePasswordallsss = await Axios.post('changePasswordall.php',{
                newpassword:user.password,
                passkeyids:passkey,
            });
            return ChangePasswordallsss.data;
        }


    render(){
        const contextValue = {
            
            rootState:this.state,
            isLoggedIn:this.isLoggedIn,
            logoutUser:this.logoutUser,
            RegisterUser:this.RegisterUser,
            LoginUser:this.LoginUser,
            plansall:this.plansall,
            planupdateall:this.planupdateall,
            audioplansall:this.audioplansall,
            bookpaysmsg:this.bookpaysmsg,
            bookpaypalall:this.bookpaypalall,
            issetpaysss:this.issetpaysss,
            userlistlimit:this.userlistlimit,
            addvideourl:this.addvideourl,
            useradscountall:this.useradscountall,
            addvideouploadall:this.addvideouploadall,
            videosectionall:this.videosectionall,
            videoeditall:this.videoeditall,
            editvideourl:this.editvideourl,
            editvideouploadall:this.editvideouploadall,
            Booksaddall:this.Booksaddall,
            editaddall:this.editaddall,
            bookeditall:this.bookeditall,
            portfolioaddall:this.portfolioaddall,
            paintingaddall:this.paintingaddall,
            editpaintingall:this.editpaintingall,
            paintingeditall:this.paintingeditall,
            userlistall:this.userlistall,
            editprofilsssall:this.editprofilsssall,
            planupdateallupgrade:this.planupdateallupgrade,
            portfolioeditall:this.portfolioeditall,
            deletesimglist:this.deletesimglist,
            Platinumhomesall:this.Platinumhomesall,
            Goldhomesall:this.Goldhomesall,
            Silverhomesall:this.Silverhomesall,
            AllVideolist:this.AllVideolist,
            videopreviewall:this.videopreviewall,
            userpreviewall:this.userpreviewall,
            AllBookslist:this.AllBookslist,
            bookpreviewall:this.bookpreviewall,
            AllArtlist:this.AllArtlist,
            artpreviewall:this.artpreviewall,
            AllMusiclist:this.AllMusiclist,
            AllSilverlist:this.AllSilverlist,
            AllGoldlist:this.AllGoldlist,
            AllPlatinumlist:this.AllPlatinumlist,
            productaddall:this.productaddall,
            serviceaddall:this.serviceaddall,
            producteditall:this.producteditall,
            serviceseditall:this.serviceseditall,
            photoeditall:this.photoeditall,
            editproductall:this.editproductall,
            editserviceall:this.editserviceall,
            deletesphotolist:this.deletesphotolist,
            AllProductslist:this.AllProductslist,
            AllServiceslist:this.AllServiceslist,
            AllPhotoslist:this.AllPhotoslist,
            Productspreviewall:this.Productspreviewall,
            Servicespreviewall:this.Servicespreviewall,
            Photospreviewall:this.Photospreviewall,
            AllAuthorInterviewslist:this.AllAuthorInterviewslist,
            AllReviewslist:this.AllReviewslist,
            AllPhotographylist:this.AllPhotographylist,
            AllMovieTrailerslist:this.AllMovieTrailerslist,
            AllBookTrailerslist:this.AllBookTrailerslist,
            AllShortVideoslist:this.AllShortVideoslist,
            AllAdvertisementlist:this.AllAdvertisementlist,
            AllPortfoliolist:this.AllPortfoliolist,
            Portfoliopreviewall:this.Portfoliopreviewall,
            Latesthomesall:this.Latesthomesall,
            Bookshomesall:this.Bookshomesall,
            addnewsletters:this.addnewsletters,
            AllArtTrailerslist:this.AllArtTrailerslist,
            AllProductsvideo:this.AllProductsvideo,
            audioreceiptall:this.audioreceiptall,
            AllServicesvideos:this.AllServicesvideos,
            ForgetMailpassword:this.ForgetMailpassword,
            ChangePasswordall:this.ChangePasswordall,



        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }
}

export default MyContextProvider;